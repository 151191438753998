jQuery(document).ready(function($) {
  if ($(window).width() < 1023) {
    $('.footer-cols__col').each(function() {
      $(this)
        .find('.footer-cols__title')
        .on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $(this)
            .next()
            .toggle();
        });
    });
  }
});
