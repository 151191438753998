jQuery(document).ready(function($) {
  $('.menu-toggle').on('click', function() {
    $('.mobile-menu__wrapper').addClass('active');
  });

  $('.mobile-menu__close-button, .mobile-menu__outer').on('click', function() {
    $('.mobile-menu__wrapper').removeClass('active');
    $('.mobile-menu').removeClass('child-active');
    $('.sub-menu').hide();
  });

  $('#mobile-menu')
    .find('.menu-item-has-children')
    .each(function() {
      $(this)
        .find('.sub-menu')
        .prepend('<li class="mobile-menu__back"><a href="#">Back</a></li>');
      $(this)
        .children('a')
        .on('click', function(e) {
          e.preventDefault();
          $(this)
            .parents('.mobile-menu')
            .addClass('child-active');
          $(this)
            .next('.sub-menu')
            .show();
        });
    });

  $(document).on('click', '.mobile-menu__back', function() {
    $(this)
      .parents('.sub-menu')
      .hide();
    $(this)
      .parents('.mobile-menu')
      .removeClass('child-active');
  });
});
